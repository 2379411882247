// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-base-technologies-js": () => import("./../../../src/pages/base-technologies.js" /* webpackChunkName: "component---src-pages-base-technologies-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-clean-design-js": () => import("./../../../src/pages/clean-design.js" /* webpackChunkName: "component---src-pages-clean-design-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-functionality-js": () => import("./../../../src/pages/custom-functionality.js" /* webpackChunkName: "component---src-pages-custom-functionality-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-pages-js": () => import("./../../../src/pages/landing-pages.js" /* webpackChunkName: "component---src-pages-landing-pages-js" */),
  "component---src-pages-multi-device-support-js": () => import("./../../../src/pages/multi-device-support.js" /* webpackChunkName: "component---src-pages-multi-device-support-js" */),
  "component---src-pages-progressive-web-apps-js": () => import("./../../../src/pages/progressive-web-apps.js" /* webpackChunkName: "component---src-pages-progressive-web-apps-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-ui-ux-js": () => import("./../../../src/pages/ui-ux.js" /* webpackChunkName: "component---src-pages-ui-ux-js" */),
  "component---src-pages-web-applications-js": () => import("./../../../src/pages/web-applications.js" /* webpackChunkName: "component---src-pages-web-applications-js" */),
  "component---src-pages-web-performance-js": () => import("./../../../src/pages/web-performance.js" /* webpackChunkName: "component---src-pages-web-performance-js" */)
}

